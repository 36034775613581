// react imports
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo';
import Carousel from 'react-multi-carousel';

import backgroundImage from '../../assets/media/images/Kezdokep.png'
import motivacio_background from '../../assets/media/images/motivacio-background.png'
import casette from '../../assets/media/images/kasette.png'
import splogo from '../../assets/media/logo/splogo.png'
import paypal from '../../assets/media/logo/paypal.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';
import CategoryComponent from '../categoryComponent/CategoryComponent'
import CarouselComponent from '../carousel/CarouselComponent'
import CarouselComponentTop from '../carousel/CarouselComponentTop'
import CategoryModal from "../modals/CategoryModal";
import { Videolist } from '../../data/musterVimeo';


import { MDBAlert, 
         MDBRow, 
         MDBIcon
          } from 'mdb-react-ui-kit';
import Gyik from '../common/Gyik'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const HomePage = (props) => {

  //MDBAlert
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // REACT ROUTER PARAMS - for processing url
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  const navigate = useNavigate();

  // STATES
  const [isCard, setIsCard] = useState(true);
  const [livevideoWidht, setLivevideoWidht] = useState(0);
  const [imageHeightRespo, setImageHeightRespo] = useState(document.getElementById("backgroundImageRespo")?.height);
  const [bookmarkList, setBookmarkList] = useState([])
  const [newLiveVideo, setNewLiveVideo] = useState([])
  
   useEffect(() => {
    if(context.isLoggedIn()){
        setIsCard(false)
        setImageHeightRespo(document.getElementById("backgroundImageRespo")?.height)
    }else{
        setImageHeightRespo(document.getElementById("backgroundImageRespo")?.height)
        setTimeout(() => setIsCard(true), 100);
    }
  }, [context.isLoggedIn()]);

  const handleWindowSizeChange = () => {
    context.handleWindowSizeChange(document.getElementById("favDialog_live")?.offsetWidth-40)
    setLivevideoWidht(document.getElementById("live-edzes")?.offsetWidth > 810 ? document.getElementById("live-edzes")?.offsetWidth/1.7 : document.getElementById("live-edzes")?.offsetWidth*0.9);
    setImageHeightRespo(document.getElementById("backgroundImageRespo")?.height)
  };

  const scrollEventListener = () => {
    if(window.scrollY < 50){
        context.onSetActiveItem("1");
    }
    if(window.scrollY > 50){
        if(cookies.get('hirlevel')?.isHirlevel !== true){
            //context.openLoginModal("feliratkozas");
        }
        
    }
    
    if(window.scrollY > document.getElementById('videotar').getBoundingClientRect().top + window.pageYOffset -150){
        context.onSetActiveItem("2");
        if(window.innerWidth >= 990){
            context.onSetIsMoreVideos();
        }
    }
    if(!context.isLoggedIn()){
        if(window.scrollY > document.getElementById('gyik').getBoundingClientRect().top + window.pageYOffset -400){
            context.onSetActiveItem("4");
        }
    }
    
   
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    document.addEventListener("scroll", scrollEventListener);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        document.removeEventListener("scroll", scrollEventListener);
    };
    
  }, []);

  const onGetVideos = () => {
    context.interAxios.get('/video', function(response) {
        context.onSetVideolist(response.data.content)
        console.log(response.data.content)
      var set = new Set(response.data.content.map(k => {return (k.category)}))
      var mySet = new Set()
      for(let i=0; i<response.data.content.length; i++){
          let array = response.data.content[i].categories;
          array.forEach(item => mySet.add(item))
      }
          context.onSetCategorylist(Array.from(mySet).sort())
      });
  }

  const onGetNewVideos = () => {
    context.interAxios.get('/video/latest', function(response) {
        context.onSetNewVideolist(response.data)
    });
  }
  
  useEffect(() => {
    if(context.isLoggedIn() && cookies.get('isMoreVideos')?.isMoreVideos === true){
        onGetVideos();
        onGetNewVideos();
        
        if(cookies.get('client')?.status !== "INACTIVE"){
          context.interAxios.get('/bookmark', function(response) {
            console.log(response.data)
              setBookmarkList(response.data)
              context.onSetBookmarkList(response.data)
        });}
    }

    context.interAxios.get('/live/next', function(response) {
    setNewLiveVideo(response.data)
    context.onSetNewLiveVideo(response.data)
    context.onSetNewLiveName(response.data.name)
    context.onSetLiveTime(response.data.start)
    //console.log(response.data)
    });
  }, [context.isLoggedIn(), cookies.get('isMoreVideos')?.isMoreVideos, context.getState()?.isMoreVideos]);


  const onPutVideoToBookmark = (id) => {
    if(cookies.get('client')?.status !== "INACTIVE"){
        context.interAxios.put('/bookmark/' + id, function(response) {
            context.interAxios.get('/bookmark', function(response) {
            context.onSetBookmarkList(response.data)
            });
            onGetVideos();
            onGetNewVideos();
        });
    }
  }

  const onDeleteVideoFromBookmark = (id) => {
    if(cookies.get('client')?.status !== "INACTIVE"){
      context.interAxios.delete('/bookmark/' + id, function(response) {
          context.interAxios.get('/bookmark', function(response) {
            context.onSetBookmarkList(response.data)
          });
          onGetVideos();
          onGetNewVideos();
      });
    }
  }


  const responsive = {
    superWidthDesktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
        partialVisibilityGutter: 9
    },
    
  };
 

  return (
    <div>
        <div className=" d-none d-lg-block">
            <section className={!context.isLoggedIn() ? "hero" : "hero loggedin"} id="hero" >
                <div style={{overflow: "hidden"}}>
                {context.getState()?.fotoList !== undefined && context.getState()?.fotoList !== null &&(
                    <Carousel
                    additionalTransfrom={0} 
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    renderArrowsWhenDisabled={true}
                    renderDotsOutside={true}
                    slidesToSlide={1}
                    centerMode={false}
                    autoPlay={true}
                    autoPlaySpeed={15999}
                    keyBoardControl={true}
                    customTransition="transform 1000ms ease-in-out"
                    transitionDuration={1000}
                    containerClass="carousel-container-slide"
                >

                    {context.getState()?.fotoList.map((k, n) => {return (
                        <img
                            className='w-100 backgroundImage-style'
                            itemID={k.id}
                            src={k.url}
                            alt={k.id}
                            key={n}
                        >
                        </img>
                    
                    )})}
                
                </Carousel>
                )}

                </div>
                {isCard && (
                    <div className="apply-card">
                        <img src={casette} alt="casette" onClick={() => context.openLoginModal("signup")}/>
                        <div className="d-grid green" >
                        <span className="description-2 pt-2">Kérdésed van?</span><br/><span className="description-2 pt-2"> Írj a <a style={{color: "#ffffff", textDecorationLine: "underline"}} href={"mailto:rendezveny@rubintreka.hu"}> videotar@rubintreka.hu</a> emailcímre!</span>
                       
                    </div>
                    </div>

                )}   
                

            
            </section>
        </div>
        <div className=" d-block d-lg-none">
            <section className={!context.isLoggedIn() ? "hero" : context.getState().liveTime !== undefined  ? "hero loggedin live" : "hero loggedin"} id="hero" >
                <img src={backgroundImage} id="backgroundImageRespo" className={"backgroundImage-style"}/>
                <div className=" apply-card-3" >
                        <img className="card-image" src={casette} alt="casette" onClick={() => context.openLoginModal("signup")}/>
                        <div className="d-grid green mt-3" >
                        <span className="description-2">Kérdésed van?</span><br/><span className="description-2"> Írj a <a style={{color: "#ffffff", textDecorationLine: "underline"}} href={"mailto:rendezveny@rubintreka.hu"}> videotar@rubintreka.hu</a> emailcímre!</span>
                        </div>
                        {/*<span className="title">Legyél a videótáram legaktívabb tagja!</span>
                        <div className="price">
                            <MDBRow style={{width: "100%"}} className="ps-4 pt-2">
                            <span className="description">Havidíj,</span>
                            </MDBRow>
                            <MDBRow className=" text-center">
                            <span className="price-text">2990 Ft</span>
                            </MDBRow>
                        </div>
                        <span className="description-2 pt-2">Mostantól a fizetés Simple Pay és Paypallal is lehetséges.</span>
                        <div className="d-flex align-items-center justify-content-start logos pt-3">
                            <img className="simple-image" src={splogo}/>
                            <img className="paypal-image" src={paypal}/>
                        </div>*/}
                    </div>
            </section>
        </div>
      
      
        <section className={"videotar" + (!context.isLoggedIn() ?  " isLogedIn-false" : "")}>
        {!context.isLoggedIn() && (
            <div className="motivation-video d-grid justify-content-center align-items-center motivatio-title" id="motivation-video">
               
                <div className="d-flex justify-content-center align-items-center motivatio-title" >
                    <span>MIÉRT CSATLAKOZZ?</span>
                </div>
                <iframe src={"https://player.vimeo.com/video/924922301?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} allowFullScreen className="video-content"></iframe>
            </div>
        )}
        <div id="videotar" style={{overflow: "hidden", paddingTop: context.isLoggedIn() ? window.innerWidth < 990 ? "200px" : "290px" : "10px"}}>
        
            <div className={context.isLoggedIn() ? "videotar-title visible-false d-flex align-items-center justify-content-center" : "videotar-title d-flex align-items-center justify-content-center"}>
                <MDBRow style={{maxWidth: "1258px"}} className="m-0">
                    <span >VIDEÓK</span>
                </MDBRow>
            </div>  
        {!context.isLoggedIn() && (
            <>
            {context.getState()?.randomVideoList !== undefined  && context.getState()?.randomVideoList !== null && (
                <div style={{marginBottom: "65px"}}>
                    <CategoryComponent videoList={context.getState()?.randomVideoList.filter(k => k.promoUrl !== null)} category={"Alakreform"}/>
                </div>
            )}
            </>
        )}
        {context.isLoggedIn() && (
            <>
                {cookies.get('isMoreVideos')?.isMoreVideos !== true ? 
                <>
                    {context.getState()?.randomVideoList !== undefined  && context.getState()?.randomVideoList !== null && (
                        <div style={{marginBottom: "65px"}}>
                            <CategoryComponent videoList={window.innerWidth < 990 ?  context.getState()?.randomVideoList.filter(k => k.promoUrl !== null).filter((k,n) => n < 4) : context.getState()?.randomVideoList.filter(k => k.promoUrl !== null)} category={"Alakreform"}/>
                            <button className="more-video-button" onClick={() => context.onSetIsMoreVideos()}><span>Az összes videó megtekintése</span></button>
                        </div>
                    )}
                </>:
                <>
             

{context.getState()?.categoryList  && context.getState()?.videoList && context.getState()?.categoryList.filter((k,n) => k === "2 hetes életmódváltó program").map((k, n) => {return (
                        <div style={{position: "relative", marginTop: "15px", marginBottom: "10px"}} id={"carousel-0"} key={n}>
                            <button className="category-title">{k}</button>  
                            <CarouselComponentTop category={k !== null ? k : null} videoList={context.getState()?.videoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
                        </div>
                    )})}

                    {context.getState()?.newVideoList && context.getState()?.newVideoList.length > 0 && (
                    <div style={{position: "relative", marginBottom: "10px"}} id={"carousel-0"}>
                        <div className="category-title">Legújabb feltöltések</div>  
                        <CarouselComponentTop category={"Legújabb feltöltések"} videoList={context.getState()?.newVideoList.filter((k, n) => n < 9 )}   onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
                    </div>
                    )}

                    
        
                    {context.getState()?.bookmarkList && context.getState()?.bookmarkList?.length > 0 && (
                    <div style={{position: "relative", marginTop: "20px", marginBottom: "20px"}} id={"carousel-a"}>
                        <div className="category-title" 
                        >Kedvencek</div>  
                        <CarouselComponent category={"Kedvencek"} videoList={context.getState()?.bookmarkList} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark} onPutVideoToBookmark={onDeleteVideoFromBookmark} />
                    </div>
                    )}
        
                    {context.getState()?.categoryList  && context.getState()?.videoList && context.getState()?.categoryList.filter((k,n) => n < 2 && k !== "Réka LIGHT - kezdőknek" && k !== "2 hetes életmódváltó program").map((k, n) => {return (
                    <div key={n} style={{position: "relative", marginBottom: "20px"}} id={"carousel-" + n+1}>
                        <button className="category-title d-none d-lg-flex" 
                                onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: context.getState()?.videoList}})}
            
                        >{k !== null ? k : "General"}<span className="link-text"> Fedezze fel mindegyiket</span></button>  
                        <div className="category-title d-block d-lg-none" 
                        >{k !== null ? k : "General"}</div>  
                        <CarouselComponent category={k !== null ? k : null} videoList={context.getState()?.videoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
                    
                    </div>
                        
                    )})}
        
                    {context.getState()?.categoryList  && context.getState()?.videoList && context.getState()?.categoryList.filter((k,n) => k === "Réka LIGHT - kezdőknek").map((k, n) => {return (
                        <div style={{position: "relative", marginTop: "15px", marginBottom: "10px"}} id={"carousel-0"} key={n}>
                            <button className="category-title">{k}</button>  
                            <CarouselComponentTop category={k !== null ? k : null} videoList={context.getState()?.videoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
                        </div>
                    )})}
        
                    {context.getState()?.categoryList  && context.getState()?.videoList && context.getState()?.categoryList.filter((k,n) => n >1 && k !== "Réka LIGHT - kezdőknek" && k !== "2 hetes életmódváltó program").map((k, n) => {return (
                    <div key={n} style={{position: "relative", marginBottom: "15px"}} id={"carousel-" + n+1}>
                        <button className="category-title d-none d-lg-flex" 
                                onClick={() => navigate('/categorydetail', { state: { categoryName: k, videoList: context.getState()?.videoList}})}
            
                        >{k !== null ? k : "General"}<span className="link-text"> Fedezze fel mindegyiket</span></button>  
                        <div className="category-title d-block d-lg-none" 
                        >{k !== null ? k : "General"}</div> 
                        <CarouselComponent category={k !== null ? k : null}  videoList={context.getState()?.videoList} onPutVideoToBookmark={onPutVideoToBookmark} onDeleteVideoFromBookmark={onDeleteVideoFromBookmark}/>
            
                    </div>
                    )})}
                </>
                }
            
            </>
        )}
        </div>
        </section>
   
      
        
        
        
        {!context.isLoggedIn() && (
            <section className="gyik" id="gyik">
                <MDBRow className="m-0">
                    <div className="gyik-title d-flex align-items-center justify-content-center">
                        <span>Gyakran ismételt kérdések</span>
                    </div>
                </MDBRow>
                <div className="d-flex align-items-center justify-content-center">
                    <MDBRow style={{maxWidth: "640px", width: "100%"}} className="m-0">
                        <Gyik/>
                    </MDBRow>
                </div>
            </section>
        )}
       
        <MDBAlert
            show={alert.alert}
            color='primary'
            autohide
            position='top-center'
            offset={50}
            delay={4000}
            appendToBody
            onClosed={() => { setAlert({alert: false, alertText: ""})}}
        >{alert.alertText}</MDBAlert>
  {/*     
        <dialog id="favDialog_live">
            <form>
                <div className="d-flex justify-content-end align-items-center pb-4">
                    <button className="text-button-dialog" value="cancel" formMethod="dialog" 
                    onClick={() => {setVimeoUrl(""); document.getElementById("favDialog_live")?.close(); context.onSetActiveItem("1")
                                document.body.style.overflow = context.getState()?.isScroll ? 'hidden' : 'scroll'}}>
                        <MDBIcon fas icon="times" size="lg" color="white" />
                    </button>
                </div>
                <div className="d-flex">
                <iframe src={document.getElementById("favDialog_live")?.open ? newLiveVideo?.videoUrl +"&autoplay=1" : ""} width={context.getState()?.videoWidht + "px"} height={context.getState()?.videoWidht*0.527344 + "px"} 
                    allow="autoplay"
                    allowFullScreen="allowFullScreen"
                    mozallowfullscreen="mozallowfullscreen" 
                    msallowfullscreen="msallowfullscreen" 
                    oallowfullscreen="oallowfullscreen" 
                    webkitallowfullscreen="webkitallowfullscreen" ></iframe>
                <iframe src={newLiveVideo?.interactionUrl} width="300px" height={context.getState()?.videoWidht*0.527344 + "px"} frameBorder="0" allowFullScreen="allowFullScreen"
                    mozallowfullscreen="mozallowfullscreen" 
                    msallowfullscreen="msallowfullscreen" 
                    oallowfullscreen="oallowfullscreen" 
                    webkitallowfullscreen="webkitallowfullscreen"></iframe>
                </div>
            </form>
        </dialog>
        */} 
        
    </div>


  );
};

export default HomePage;
