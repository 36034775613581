// react imports
import React, { useContext, useState, useEffect } from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";

import AppContext from '../../AppContext';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import sidenavButton from '../../assets/media/icons/sidenavButton.svg'
import alakreform_icon from '../../assets/media/icons/alakreform.png';
import moment from "moment";
import emlekezteto from '../../assets/media/icons/Yoga Logo.svg'
import CountDown from "../common/timer/CountDown";
import { getOrCreateDeviceUniqueId } from '../../utils/deviceuniqueid';

// MDBOOTSTRAP IMPORTS
import { MDBContainer,  
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBBtn,
  MDBRow,
  MDBCol } from "mdb-react-ui-kit";

// IMPORT LAYOUT COMPONENTS
import Footer from "./footer/Footer";
import Header from "./header/Header";
import SignUpModal from "../modals/SignUpModal";
import ProfileEditModal from "../modals/ProfileEditModal";
import SignInModal from "../modals/SignInModal";
import SimplePayModal from "../modals/SimplePayModal";
import CouponSimplePayModal from "../modals/CouponSimplePayModal";
import PayPalModal from "../modals/PayPalModal";
import SimplePayModalSikertelen from "../modals/SimplePayModalSikertelen";
import SimplePayModalTorles from "../modals/SimplePayModalTorles";
import SimplePayModalTullepes from "../modals/SimplePayModalTullepes";
import PasswordresetModal from "../modals/PasswordresetModal";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
import GyikModal from "../modals/GyikModal";
import GeneralInfoModal from "../modals/GeneralInfoModal";
import CookiesModal from "../modals/CookiesModal";
import ElofizetekModal from "../modals/ElofizetekModal";
import NincsLiveModal from "../modals/NincsLiveModal";
import ProfileDeleteModal from "../modals/ProfileDeleteModal";
import PaymentDeleteModal from "../modals/PaymentDeleteModal";
import HirlevelModal from "../modals/HirlevelModal";
import UzenetModal from "../modals/UzenetModal";
import FeliratkozasHirlevelModal from "../modals/FeliratkozasHirlevelModal";
import BuyCouponWithoutLoginModal from "../modals/BuyCouponWithoutLoginModal";
import BuyCouponWithLoginModal from "../modals/BuyCouponWithLoginModal";
import InactiveModal from "../modals/InactiveModal";
import JatekModal from "../modals/JatekModal";
import FirstWarningModal from "../modals/FirstWarningModal";
import LastWarningModal from "../modals/LastWarningModal";
import PaypalRegistration from "../modals/PaypalRegistration";
import privacy from '../../static/jogi/privacy.pdf'
import Cookies from 'universal-cookie';
const cookies = new Cookies();




function Layout({ children }) {
  const context = useContext(AppContext);
  const [searchParams] = useSearchParams();
  
  
  // STATES
  const [basicOpen, setBasicOpen] = useState(false);
  const [simpleId, setSimpleId] = useState(false);
  const [cookiesIsAccepted, setCookiesIsAccepted] = useState(getCookieConsentValue());
  const [basicCollapse1, setBasicCollapse1] = useState(false);
  // react router navigation
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(moment(context.getState()?.liveTime));
  //const [targetTime, setTargetTime] = useState(moment("2023-09-18 16:00"));
  const timeBetween = moment.duration(targetTime.diff(currentTime));


  // events
  const loginEvent = (email, password) => {
    context.setIsLoading(true)
    const deviceUniqueId = getOrCreateDeviceUniqueId();
    context.interAxios.post('/auth/login', {password: password, email: email, uniqueId: deviceUniqueId}, function(response) {
      context.onSignIn(response.data);
      context.setIsLoading(false);
      context.closeLoginModal();
      
    }, function(error) {
      alert("Belépés sikertelen! Ellenörizze megadott adatait és próbálkozzon újra! Köszönjük türelmét!");
    });

  };

  const showLogoutClicked = () => {
    context.onLogout();
    //navigate('/marketplace');
  }

  useEffect(() => {
    if(window.location.pathname === '/sikeresfizetes'){
      context.interAxios.post('/simplepay/back', {r: searchParams.get("r"), s: searchParams.get("s")}, function(response) {
        setTimeout(() => {
          navigate('/fooldal');
          context.openLoginModal("succesfull-pay")
        }, 500)
      });
    }
  }, [window.location.pathname === '/sikeresfizetes']);

  useEffect(() => {
    if(window.location.pathname === '/sikereskuponvasarlas'){
      context.interAxios.post('/simplepay/back', {r: searchParams.get("r"), s: searchParams.get("s")}, function(response) {
        setTimeout(() => {
          navigate('/fooldal');
          context.openLoginModal("succesfull-pay-coupon")
        }, 500)
      });
    }
  }, [window.location.pathname === '/sikereskuponvasarlas']);

  useEffect(() => {
    if(window.location.pathname === '/cookies'){
    cookies.set('CookieConsent',  {CookieConsent: true,
        szuksegesSuti: true,
        statisztikaSuti: true,
        reklamSuti: true
      }, { path: '/'});
      context.setConsent({ad_storage: "granted", 
                          analytics_storage : "granted", 
                          functionality_storage : "granted", 
                          security_storage: "granted", 
                          personalization_storage : "granted", 
                          ad_personalization: "granted", 
                          ad_user_data: "granted"});
    }
  }, [window.location.pathname === '/cookies']);

  useEffect(() => {
    if(window.location.pathname === '/nocookies'){
    cookies.set('CookieConsent',  {CookieConsent: true,
        szuksegesSuti: false,
        statisztikaSuti: false,
        reklamSuti: false
      }, { path: '/'});
      context.setConsent({ad_storage: "denied", 
                          analytics_storage : "denied", 
                          functionality_storage : "denied", 
                          security_storage: "granted", 
                          personalization_storage : "denied", 
                          ad_personalization: "denied", 
                          ad_user_data: "denied"});
    }
  }, [window.location.pathname === '/nocookies']);

  
  useEffect(() => {
    if(window.location.pathname === '/paypal-siker'){
      context.interAxios.post('/paypal/back', {subscription_id: searchParams.get("subscription_id")}, function(response) {
        setTimeout(() => {
          navigate('/fooldal');
        context.openLoginModal("succesfull-paypal")
        }, 500)
      });
    }
  }, [window.location.pathname === '/paypal-siker']);


  if(window.location.pathname === '/sikertelenfizetes'){
    //setSimpleId(() => atob(searchParams.get("r").split("%")[0]).split('"t":')[1].split(',"e":')[0])
    setTimeout(() => {
      navigate('/fooldal');
      context.openLoginModal("sikertelenfizetes")
    }, 500)
  }
  if(window.location.pathname === '/megszakitva'){
    setTimeout(() => {
      navigate('/fooldal');
    context.openLoginModal("megszakitva")
    }, 500)
  }

  if(window.location.pathname === '/visszalepes'){
    setTimeout(() => {
      navigate('/fooldal');
    context.openLoginModal("megszakitva")
    }, 500)
  }

  if(window.location.pathname === '/paypal-megsem'){
    setTimeout(() => {
      navigate('/fooldal');
    context.openLoginModal("megszakitva")
    }, 500)
  }

  if(window.location.pathname === '/idotullepes'){
    navigate('/fooldal');
    context.openLoginModal("idotullepes")
  }

  useEffect(() => {
    if(window.location.pathname.split("/")[window.location.pathname.split("/").length-1] === "change-password"){
      context.openLoginModal("resetpassword")
      
    }
    if(searchParams.get("r") !== null){
      setSimpleId(() => atob(searchParams.get("r").split("%")[0]).split('"t":')[1].split(',"e":')[0])
    }
  /*  if(cookies.get('nyeremeny')?.isNyeremeny !== true){
      context.openLoginModal("nyeremenyjatek")
    }*/
    
    
  }, []);

  useEffect(() => {
    setCookiesIsAccepted(getCookieConsentValue());
    context.onRenderFalse()
  }, [context.getState()?.isRender]);

  
  

  return (
    <>
    <MDBContainer fluid className='p-0'>
      {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "livevideoplay" && (
      <div className=" d-none d-lg-block">
        <Header
          context={context}
          loggedIn={context.isLoggedIn()}
          logoutClicked={showLogoutClicked}
        />
      </div>
      )}
     
      
       <MDBNavbar expand='lg' sticky light bgColor='light' className="navbar-bottom d-none d-lg-block">
         <MDBContainer fluid className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
         <MDBNavbarBrand onClick={() => {
                context.onSetActiveItem("1");
                navigate('/fooldal');
                window.scrollTo({ top: 0, behavior: 'smooth' })}}>
            
              <img
                  src={alakreform_icon}
                  height='35'
                  alt=''
                  loading='lazy'
              />
          </MDBNavbarBrand>
            
             <MDBNavbarItem className='videok' onClick={() => {
                                context.onSetActiveItem("2");
                                context.onSetIsMoreVideos();
                                navigate('/fooldal');
                                setTimeout(() => {var element = document.getElementById('videotar');
                                var headerOffset = !context.isLoggedIn() ? 59 : -120;
                                var elementPosition = element.getBoundingClientRect()?.top;
                                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                              
                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: "smooth"
                                })}, 500);
                                }}>
               <MDBNavbarLink  active={context.getState()?.activeItem === "2"} >Videók</MDBNavbarLink>
             </MDBNavbarItem>
             {context.isLoggedIn()  && (
             <MDBNavbarItem className='live-edzes-nav' onClick={() => {
              if(context.getState()?.liveTime !== undefined){
                if(cookies.get('client')?.status !== "INACTIVE"){
                  context.onSetActiveItem("3");
                  navigate('/livevideoplay');
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }else{
                    context.openLoginModal("inactive")
                }
              }else{
                context.openLoginModal("nincslivevideo")
              }
                }}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "3"} >LIVE edzés</MDBNavbarLink>
             </MDBNavbarItem>)}

             {context.isLoggedIn()  && (
             <MDBNavbarItem className='atalakitoprogram' >
                <MDBNavbarLink active={context.getState()?.activeItem === "8"}  tag='a' className='nav-link' role='button' onClick={() => {context.onSetActiveItem("8"); navigate('/villamatalakitoprogram'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500)}}>
                  <span className="animate-charcter">Életmódváltó program</span>
                </MDBNavbarLink>
                
             
             </MDBNavbarItem>)}

              {context.isLoggedIn()  ?
             <MDBNavbarItem className='atalakitoprogram' >
                <MDBNavbarLink active={context.getState()?.activeItem === "8"}  tag='a' className='nav-link' role='button' onClick={() => {
                context.openLoginModal("buycouponwithlogin"); context.onSetActiveItem("8");  
              }}>
                  <span className="animate-charcter">Ajándékutalvány</span>
                </MDBNavbarLink>
              </MDBNavbarItem>:
               <MDBNavbarItem className='atalakitoprogram' >
               <MDBNavbarLink active={context.getState()?.activeItem === "8"}  tag='a' className='nav-link' role='button' onClick={() => {
               context.openLoginModal("buycouponwithoutlogin"); context.onSetActiveItem("8");  
             }}>
                 <span className="animate-charcter">Ajándékutalvány</span>
               </MDBNavbarLink>
             </MDBNavbarItem>
              }
             
             <MDBNavbarItem className="live-edzes-nav" onClick={() => {
                context.onSetActiveItem("9"); navigate('/programlista'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);  
                }}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "9"} ><span >Rendezvények, események</span></MDBNavbarLink>
             </MDBNavbarItem>
             
             <MDBNavbarItem className="live-edzes-nav" onClick={() => {window.open("https://webshop.rubintreka.hu/", '_blank').focus()}}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "30"} ><span >Alakreform webshop</span></MDBNavbarLink>
             </MDBNavbarItem>
             {!context.isLoggedIn() && (
             <MDBNavbarItem className='gyik-kerdesek' onClick={() => {
                context.onSetActiveItem("4");
                navigate('/fooldal');
                setTimeout(() => {
                var element = document.getElementById('gyik');
                var headerOffset = 59;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                })}, 500);}}>
               <MDBNavbarLink  active={context.getState()?.activeItem === "4"} >Gyakori kérdések</MDBNavbarLink>
             </MDBNavbarItem>)}
             <MDBNavbarItem className='atalakitoprogram' onClick={() => {
                context.onSetActiveItem("5"); navigate('/kapcsolatok'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);  
               }}>
               <MDBNavbarLink active={context.getState()?.activeItem === "5"} >Kapcsolat</MDBNavbarLink>
             </MDBNavbarItem>
             <MDBNavbarItem className='hirlevel' onClick={() => {
              navigate('/fooldal');
                context.onSetActiveItem("20");
                setTimeout(() => {
                  var element = document.getElementById('footer');
                  var headerOffset = 59;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                
                  window.scrollTo({
                      top: offsetPosition,
                      behavior: "smooth"
                  })}, 1000);  
               }}>
               <MDBNavbarLink active={context.getState()?.activeItem === "20"} >Feliratkozás hírlevélre</MDBNavbarLink>
             </MDBNavbarItem>
             {/*context.isLoggedIn() && (
               <MDBNavbarItem className='atalakitoprogram' onClick={() => {
                context.onSetActiveItem("10"); navigate('/nyeremenyjatek'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);  
               }}>
               <MDBNavbarLink active={context.getState()?.activeItem === "10"} ><span className="animate-charcter">Nyereményjáték</span></MDBNavbarLink>
             </MDBNavbarItem>
             )*/}
             {context.isLoggedIn() && (
               <MDBNavbarItem className='kapcsolat' onClick={() => {
                context.openLoginModal("editprofile");  
              }}>
                <MDBNavbarLink >Fiókom</MDBNavbarLink>
              </MDBNavbarItem>
             )}
             {context.isLoggedIn() && (
               <MDBNavbarItem className='kilepes' onClick={() => {context.onSetActiveItem("1"); navigate('/fooldal');  window.scrollTo({ top: 0, behavior: 'smooth' });  setTimeout(() => {
                context.onLogout()
              }, 500)}}>
                 <MDBNavbarLink >Kilépés</MDBNavbarLink>
               </MDBNavbarItem>
             )}
         </MDBContainer>
       </MDBNavbar>
       
      <div className=" d-block d-lg-none">
        <div className="sidenav-bottom-respo">
       {/* <div>
          <MDBContainer fluid className="d-flex justify-content-center align-items-center p-0">
          <div className="live-training-text">
            <div className="first-element">
              <span>Technikai probléma</span>
            </div>
            <div className="second-element">
              <span>Átmenetileg csak korlátozott számú videó érhető el az oldalon. A megértéseteket köszönjük!</span>
            </div>
          </div>
          </MDBContainer>
        </div>*/}
        {context.isLoggedIn() && context.getState().liveTime !== undefined && (
        <div>
          <MDBContainer fluid className="d-flex justify-content-center align-items-center p-0">
              <div className="live-training-text">
                <div className="first-element">
                  <img src={emlekezteto}/>
                  <span style={{marginRight: "10px"}}>Élő emlékeztető: </span><br/>
                </div>
                <div className="second-element">
                  {context.getState()?.liveTime > moment().valueOf() ? <>
                    <span className="counter">
                    {timeBetween.days() > 0 && (
                      <>{timeBetween.days()} nap </>
                    )
                    }
                    {timeBetween.hours() > 0 && (
                      <>{timeBetween.hours()} óra </>
                    )}
                    
                    <>{timeBetween.minutes()} perc </>

                    {timeBetween.days() === 0 && timeBetween.hours() === 0 && timeBetween.minutes() < 10 && (
                      <>{timeBetween.seconds()} masodperc </>
                    )}múlva indul a következő {context.getState()?.newLiveName}!!
                  </span>
                  </> :
                  <span style={{marginLeft: "10px"}}>Jelenleg folyamatban van egy {context.getState()?.newLiveName}!! </span>}
                </div>
            </div>
          </MDBContainer>
        </div>
                    )}
        <MDBRow className="m-0 pt-2 pb-3" style={{width: "100%"}}>
          <MDBCol md="9" sm="9" size="9" className="d-flex justify-content-start align-items-center ps-4">
            <div className="d-flex justify-content-center align-items-center title-text">
              <span>RUBINT RÉKA - ONLINE VIDEÓTÁR</span>
            </div>
          </MDBCol>
          <MDBCol md="3" sm="3" size="3" className="d-flex justify-content-start align-items-center ps-4">
              <MDBBtn onClick={() => setBasicOpen(!basicOpen)} className="sidenavButton">
                <img src={sidenavButton} />
              </MDBBtn>
            </MDBCol>
        </MDBRow>
        {!context.isLoggedIn() &&(
          <>
          {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "livevideoplay" && (
  <MDBRow className="m-0 pb-3 ">
           
  <MDBCol md="12" sm="12" size="12" className="d-flex justify-content-start align-items-center">
    <div className="">
      <button className="rergistration-button-sidenav white" onClick={() => context.openLoginModal("signin")}>
        <span>BELÉPÉS</span>
      </button>
    </div>
    <div className="">
      <button className="rergistration-button-sidenav black" onClick={() => context.openLoginModal("signup")}>
        <span>REGISZTRÁCIÓ</span>
      </button>
    </div>
  </MDBCol>
  
</MDBRow> 
          )}
          </>
      
            )}
        </div>
        <MDBSideNav isOpen={basicOpen} absolute right getOpenState={(e) => setBasicOpen(e)}>
        <MDBSideNavMenu>
          <MDBSideNavItem onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });  setBasicOpen(!basicOpen); navigate('/fooldal')}}>
            <MDBSideNavLink >
            Főoldal
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem onClick={() => {
                                context.onSetIsMoreVideos();
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                 setBasicOpen(!basicOpen); navigate('/fooldal')}}>
            <MDBSideNavLink >
              Videók
            </MDBSideNavLink>
          </MDBSideNavItem>
          {context.isLoggedIn() && (
          <MDBSideNavItem onClick={() => {
            if(context.getState()?.liveTime !== undefined){
              if(cookies.get('client')?.status !== "INACTIVE"){
                context.onSetActiveItem("3");
                navigate('/livevideoplay');
                window.scrollTo({ top: 0, behavior: 'smooth' })
                setBasicOpen(!basicOpen)
              }else{
                  context.openLoginModal("inactive")
              }
            }else{
              context.openLoginModal("nincslivevideo")
            }
            }}>
               
            <MDBSideNavLink >
              LIVE edzés
            </MDBSideNavLink>
          </MDBSideNavItem>)}
          {context.isLoggedIn()  && (
             <MDBSideNavItem className='atalakitoprogram' onClick={() => {
                context.onSetActiveItem("8"); setBasicOpen(!basicOpen); navigate('/villamatalakitoprogram'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);  
                }}>
              <MDBSideNavLink  active={context.getState()?.activeItem === "8"} ><span className="animate-charcter">Életmódváltó program</span></MDBSideNavLink>
             </MDBSideNavItem>
            )}
           

            {context.isLoggedIn()  ?
             <MDBSideNavItem className='atalakitoprogram' >
                <MDBSideNavLink active={context.getState()?.activeItem === "8"}  tag='a' className='nav-link' role='button' onClick={() => {
                context.openLoginModal("buycouponwithlogin"); context.onSetActiveItem("8");  
              }}>
                  <span className="animate-charcter">Ajándékutalvány</span>
                </MDBSideNavLink>
              </MDBSideNavItem>:
               <MDBSideNavItem className='atalakitoprogram' >
               <MDBSideNavLink active={context.getState()?.activeItem === "8"}  tag='a' className='nav-link' role='button' onClick={() => {
               context.openLoginModal("buycouponwithoutlogin"); context.onSetActiveItem("8");  
             }}>
                 <span className="animate-charcter">Ajándékutalvány</span>
               </MDBSideNavLink>
             </MDBSideNavItem>
              }
          
          <MDBSideNavItem onClick={() => {
            setBasicOpen(!basicOpen); navigate('/programlista'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);  
            }}>
          <MDBSideNavLink  active={context.getState()?.activeItem === "9"} ><span>Rendezvények, események</span></MDBSideNavLink>
          </MDBSideNavItem>
          
             <MDBSideNavItem onClick={() => {window.open("https://webshop.rubintreka.hu/", '_blank').focus(); setBasicOpen(!basicOpen)}}>
              <MDBSideNavLink  active={context.getState()?.activeItem === "30"} ><span >Alakreform webshop</span></MDBSideNavLink>
             </MDBSideNavItem>
          {!context.isLoggedIn() && (
          <MDBSideNavItem  onClick={() => { var element = document.getElementById('gyik');
          var headerOffset = 125;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
          window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
          }); setBasicOpen(!basicOpen)}}>
            <MDBSideNavLink>
              Gyakori kérdések
            </MDBSideNavLink>
          </MDBSideNavItem>)}
          <MDBSideNavItem onClick={() => {navigate('/kapcsolatok');  window.scrollTo({ top: 0, behavior: 'smooth' }); setBasicOpen(!basicOpen)}}>
            <MDBSideNavLink >
              Kapcsolat
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem onClick={() => {navigate('/fooldal'); setTimeout(() => {
                  var element = document.getElementById('footer');
                  var headerOffset = 59;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                
                  window.scrollTo({
                      top: offsetPosition,
                      behavior: "smooth"
                  })}, 1000); setBasicOpen(!basicOpen)}}>
            <MDBSideNavLink >
              Feliratkozás hírlevélre
            </MDBSideNavLink>
          </MDBSideNavItem>
          {/*context.isLoggedIn()  && (
             <MDBSideNavItem className='atalakitoprogram' onClick={() => {
                context.onSetActiveItem("10"); setBasicOpen(!basicOpen); navigate('/nyeremenyjatek'); setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 500);  
                }}>
              <MDBSideNavLink  active={context.getState()?.activeItem === "8"} ><span className="animate-charcter">Nyereményjáték</span></MDBSideNavLink>
              </MDBSideNavItem>)*/}
          {context.isLoggedIn() && (
              <MDBSideNavItem className='kapcsolat' onClick={() => {
              context.openLoginModal("editprofile");  
            }}>
              <MDBSideNavLink >Fiókom</MDBSideNavLink>
            </MDBSideNavItem>
            )}
          {context.isLoggedIn() && (
          <MDBSideNavItem onClick={() => {navigate('/fooldal'); setBasicOpen(!basicOpen); window.scrollTo({ top: 0, behavior: 'smooth' });  setTimeout(() => {
            context.onLogout()
          }, 500)}}>
            <MDBSideNavLink >
              Kilépés
            </MDBSideNavLink>
          </MDBSideNavItem>)}
        </MDBSideNavMenu>
        </MDBSideNav>
            </div>
      <main>
      <Outlet/>
      </main>
      <Footer />
      {context.getState()?.modalName === "signup" && (
        <SignUpModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
        context={context}
      />
      )}
      {context.getState()?.modalName === "editprofile" && (
        <ProfileEditModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
      />
      )}
      {context.getState()?.modalName === "deleteProfile" && (
        <ProfileDeleteModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "signin" && (
        <SignInModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
      />
      )}
      {context.getState()?.modalName === "resetpassword" && (
        <PasswordresetModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "forgotpassword" && (
        < ForgotPasswordModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
      />
      )}
      {context.getState()?.modalName === "gyik" && (
        <GyikModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "succesRegistration" && (
        <GeneralInfoModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "elofizetekPopup" && (
        <ElofizetekModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "succesfull-pay" && (
        <SimplePayModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        simpleId={simpleId}
      />
      )}
       {context.getState()?.modalName === "succesfull-pay-coupon" && (
        <CouponSimplePayModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        simpleId={simpleId}
      />
      )}
      {context.getState()?.modalName === "succesfull-paypal" && (
        <PayPalModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        simpleId={simpleId}
      />
      )}
      {context.getState()?.modalName === "sikertelenfizetes" && (
        <SimplePayModalSikertelen
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        simpleId={simpleId}
      />
      )}
      {context.getState()?.modalName === "megszakitva" && (
        <SimplePayModalTorles
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "idotullepes" && (
        <SimplePayModalTullepes
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "hirlevel" && (
        <HirlevelModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "uzenet" && (
        <UzenetModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "feliratkozas" && (
        <FeliratkozasHirlevelModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "inactive" && (
        <InactiveModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "nyeremenyjatek" && (
        <JatekModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "firstwarning" && (
        <FirstWarningModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "lastwarning" && (
        <LastWarningModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "paypalRegistration" && (
        <PaypalRegistration
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
       {context.getState()?.modalName === "nincslivevideo" && (
        <NincsLiveModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "cookiesmodal" && (
        <CookiesModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}

      {context.getState()?.modalName === "fizeteslemondas" && (
        <PaymentDeleteModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}

      {context.getState()?.modalName === "buycouponwithoutlogin" && (
        <BuyCouponWithoutLoginModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}

      {context.getState()?.modalName === "buycouponwithlogin" && (
        <BuyCouponWithLoginModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      
    </MDBContainer>
    {!cookiesIsAccepted && (
      <CookieConsent
          location="bottom"
          buttonWrapperClasses="cookie-button-classes"
          contentClasses="cookie-content"
          buttonText="Elfogadom"
          expires={31}
          style={{ background: "#f9f9fa", color: "#252626", borderTop: "2px solid #EBEAEF" }}
          buttonClasses="accept"
          onAccept={() => {context.setConsent({ad_storage: "granted", analytics_storage : "granted", functionality_storage : "granted", security_storage: "granted", personalization_storage : "granted", ad_personalization: "granted", ad_user_data: "granted"}); context.onRenderTrue()}}
          enableDeclineButton
          declineButtonText="Nem fogadom el"
          declineButtonClasses="reject"
          onDecline={() => {context.setConsent({ad_storage: "denied", analytics_storage : "denied", functionality_storage : "denied", security_storage: "granted", personalization_storage : "denied", ad_personalization: "denied", ad_user_data: "denied"}); context.onRenderTrue()}}
        >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Ez a weboldal a felhasználói élmény fokozása, a felhasználó kényelme, a weboldal megfelelő működése, továbbá statisztikai és reklámozási célok érdekében sütiket (cookiekat) használ. Részletesebb tájékoztatást az 
                <a href={privacy} target="_blank">
                  <span className="help-text"> adatvédelmi tájékoztatóban</span>
                </a>  olvashat. <a onClick={() => context.openLoginModal("cookiesmodal") } className="help-text" style={{cursor: "pointer"}}> További lehetöségek</a>
              </div>
            </div>
         
      </CookieConsent>
    )}
    
    </>
  );
}

export default Layout;
